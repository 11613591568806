<template>
  <div class="flex column justify-center trilha-card card-relative">
    <div class="card-info-trilha full-width">
      <div class="header col-12 q-pt-md">
        <div class="avatar-notification q-mx-md flex items-center">
          <Badge iconBadge="book" typeBadge="Conhecimento" />
        </div>
        <div class="title-Info flex items-center">
          <h6>
            {{ course.title }}
          </h6>
          <q-rating
            v-model="course.avg_evaluation"
            max="5"
            size="22px"
            color="yellow"
            icon="star_border"
            icon-selected="star"
            icon-half="star_half"
            readonly
          >
            <template v-slot:tip-1>
              <q-tooltip>{{ course.avg_evaluation }}</q-tooltip>
            </template>
            <template v-slot:tip-2>
              <q-tooltip>{{ course.avg_evaluation }}</q-tooltip>
            </template>
            <template v-slot:tip-3>
              <q-tooltip>{{ course.avg_evaluation }}</q-tooltip>
            </template>
            <template v-slot:tip-4>
              <q-tooltip>{{ course.avg_evaluation }}</q-tooltip>
            </template>
            <template v-slot:tip-5>
              <q-tooltip>{{ course.avg_evaluation }}</q-tooltip>
            </template>
          </q-rating>
        </div>
        <div class="flex justify-end">
          <q-btn
            size="md"
            class="q-mr-md"
            color="default-pink"
            flat
            title="Mais"
          >
            <q-icon
              size="md"
              class="material-icons-outlined"
              name="more_horiz"
            />

            <q-menu>
              <q-list style="min-width: 100px">
                <q-item
                  v-show="btnEditCourse"
                  clickable
                  v-close-popup
                  :to="{ name: 'new-trail', query: { id: course.id } }"
                >
                  <q-item-section avatar>
                    <q-icon
                      size="sm"
                      color="default-pink"
                      class="material-icons-outlined"
                      name="edit_note"
                    />
                  </q-item-section>
                  <q-item-section> Editar </q-item-section>
                </q-item>

                <q-item
                  v-show="btnEditCourse"
                  clickable
                  v-close-popup
                  @click="accessClass"
                >
                  <q-item-section avatar>
                    <q-icon
                      size="sm"
                      color="default-pink"
                      class="material-icons-outlined"
                      name="school"
                    />
                  </q-item-section>
                  <q-item-section> Turma </q-item-section>
                </q-item>

                <q-item
                  v-show="!finishedTrail && btnEditCourse"
                  clickable
                  v-close-popup
                  :to="{ name: 'finish-trail', query: { courseId: course.id } }"
                >
                  <q-item-section avatar>
                    <q-icon
                      size="sm"
                      color="default-pink"
                      class="material-icons-outlined"
                      name="done"
                    />
                  </q-item-section>
                  <q-item-section> Finalizar </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="cardRating = true">
                  <q-item-section avatar>
                    <q-icon
                      size="sm"
                      color="default-pink"
                      class="material-icons-outlined"
                      name="star_border"
                    />
                  </q-item-section>
                  <q-item-section> Avaliar </q-item-section>
                </q-item>

                <q-item
                  clickable
                  v-close-popup
                  v-show="finishedTrail"
                  target="_blank"
                  :to="{ name: 'certificate', query: { courseId: course.id } }"
                >
                  <q-item-section avatar>
                    <q-icon
                      size="sm"
                      color="default-pink"
                      class="material-icons-outlined"
                      name="workspace_premium"
                    />
                  </q-item-section>
                  <q-item-section> Certificado </q-item-section>
                </q-item>
                <q-item
                  clickable
                  v-show="btnEditCourse"
                  v-close-popup
                  arget="_blank"
                  @click="() => (showDeleteDialog = true)"
                >
                  <q-item-section avatar>
                    <q-icon
                      size="sm"
                      color="default-pink"
                      class="material-icons-outlined"
                      name="delete"
                    />
                  </q-item-section>
                  <q-item-section> Desativar </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
      <div class="col-12 q-mt-md">
        <p class="description">
          {{ course.short_desc }}
        </p>
      </div>
      <div class="container-info">
        <div class="info-trail">
          <div class="item-trail">
            <q-icon
              v-if="course.course_type === 1"
              name="language"
              size="sm"
              color="default-pink"
              class="q-ml-md q-mr-sm"
            />

            <Person v-else width="24px" height="24px" class="q-ml-md q-mr-sm"  color="var(--q-secundary)"/>
            <label class="info-text">
              {{
                course.course_type === 1 ? "Para grupos" : "Para usuários"
              }}</label
            >
          </div>
          <div class="item-trail">
            <Teacher class="q-mr-sm" color="var(--q-secundary)" />
            <label class="info-text">{{ course.name_tutor }}</label>
          </div>
        </div>
        <div class="info-trail">
          <div class="item-trail">
            <FolderTree width="1.2rem" class="q-ml-md q-mr-sm" color="var(--q-secundary)" />
            <label class="info-text">{{ course.name_classification }}</label>
          </div>
          <div class="item-trail">
            <BookOpen class="q-mr-sm"  color="var(--q-secundary)"/>
            <label class="info-text"
              >{{ course.number_trainings }}
              {{
                course.number_trainings === 1 ? "treinamento" : "treinamentos"
              }}</label
            >
          </div>
        </div>
        <div
          v-if="btnEditCourse"
          class="cursor-pointer self-end item-trail q-mt-sm"
          @click="accessClass()"
        >
          <MultipleUser class="q-mr-sm" color="var(--q-secundary)" />
          <label class=" cursor-pointer info-text"> Participantes </label>
          <q-icon name="chevron_right" color="default-pink" size="sm" />
        </div>
      </div>
      <div class="col-12 q-px-sm q-mt-md section-image-trilha">
        <q-img :src="image" class="image-trilha" height="150px">
          <template v-slot:error>
            <q-img class="image-trilha" height="150px" :src="bannerImg">
              <div class="absolute-bottom-left section-button-images">
                <RoundedBtn
                    titleButton="Acessar"
                    v-if="!finishedTrail"
                    :onClickBtn="accessTraining"
                />
                <RoundedBtn
                    titleButton="Concluído"
                    v-else
                    :onClickBtn="accessTraining"
                    styleButton="green"
                />
              </div>
            </q-img>
          </template>
          <div
            class="absolute-bottom-left section-button-images"
            v-if="course.completed_requisite"
          >
            <RoundedBtn
              titleButton="Acessar"
              v-if="!finishedTrail"
              :onClickBtn="accessTraining"
            />
            <RoundedBtn
              titleButton="Concluído"
              v-else
              :onClickBtn="accessTraining"
              styleButton="green"
            />
          </div>
        </q-img>
      </div>
    </div>
    <div class="card-message-requisite" v-if="!course.completed_requisite">
      <label class="label-title-requisite text-center">
        Aguardando finalização do curso {{ course.title_requisite_course }}
      </label>
      <div>
        <RoundedBtn
          titleButton="Acessar Curso"
          :to="{ name: 'training', query: { id: course.requisite_course_id } }"
          route="training"
          styleButton="orange"
          :query="{ id: course.requisite_course_id }"
        />
      </div>
    </div>
    <q-dialog v-model="cardRating">
      <q-card class="my-card">
        <q-card-section>
          <q-rating
            v-model="starsWiki"
            @click="sendRatingWiki()"
            v-close-popup
            :max="5"
            size="64px"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showDeleteDialog">
      <q-card class="dialog-main">
        <q-card-section>
          <div class="text-body1">Deseja desativar Trilha</div>
        </q-card-section>

        <q-separator />

        <q-card-actions>
          <q-btn
            color="default-pink"
            label="Desativar trilha"
            @click="disabledTrail(course.id)"
            v-close-popup
          />
          <q-btn flat label="Cancelar" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <div style="height: auto; width: 70px">
        <LogoAnimated />
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
//----IMAGES
// import teachImg from "@/assets/icons/chalkboard-teacher.svg";
import bookImg from "@/assets/icons/book-open.svg";
import folderImg from "@/assets/icons/folder-tree.svg";
import multipleImg from "@/assets/icons/multiple-user.svg";
import MultipleUser from "../../../assets/icons/custom/MultipleUser.vue";
import FolderTree from "../../../assets/icons/custom/FolderTree.vue";
import BookOpen from "../../../assets/icons/custom/BookOpen.vue";
import Person from "../../../assets/icons/custom/Person.vue";
import bannerImg from "@/assets/images/banner_default.png";

import Teacher from "@/assets/icons/custom/Teacher.vue";

import RoundedBtn from "@/components/shared/buttons/RoundedBtn.vue";
import Badge from "@/components/BadgeType.vue";

import http from "@/http-common";
import { useRouter } from "vue-router";
import { onMounted, ref, computed } from "vue";
import CanUserAccessClass from "@/utils/CanUserAccessClass";

import { date, useQuasar } from "quasar";
import TrailDataServices from "@/services/TrailDataServices";

export default {
  name: "TrilhaIndoCard",
  components: {
    RoundedBtn,
    Badge,
    Teacher,
    BookOpen,
    FolderTree,
    MultipleUser,
    Person,
  },
  props: {
    course: Object,
    image: String,
    disabledTrail: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const router = new useRouter();
    const user = JSON.parse(localStorage.getItem("vuex")).userdata;
    const canUserAccessClass = new CanUserAccessClass();
    const finishedOfTutor = props.course.tutor_id == user.id ? true : false;
    const finishedTrail = props.course.finished == 1 ? true : false;

    const trailDataServices = new TrailDataServices()

    let loading = ref(false);
    let cardRating = ref(false);
    let starsWiki = ref(0);
    let btnEditCourse = ref(true);
    let showDeleteDialog = ref(false);
    const $q = new useQuasar();

    async function participateCourse() {
      loading.value = true;

      let courseId = props.course.id;

      await http
        .post(`v1/course/${courseId}/enter-as-student`)
        .then((response) => {
          loading.value = false;
          router.push({ name: "training", query: { id: courseId } });
        })
        .catch((error) => {
          loading.value = false;
        });
    }


    async function accessCourseGroups(){
      if (props.course.course_type === 1 && props.course.tutor_id !== user.id){
         return await trailDataServices.enterCourse(props.course.id)
           .then(response=> response.data)
           .catch(error=>{
             $q.notify({
               message: `Erro ao tentar acessar o curso!`,
               type: "negative",
               position: "top",
               timeout: 2000,
             });
             return false
           })
      }else{
        return true
      }
    }

    function accessClass() {
      let { id, title, finished, course_type } = props.course;

      if (course_type == 1) {
        router.push({
          name: "group-trail",
          query: { courseId: id, title: title, isFinished: finished },
        });
      } else {
        router.push({
          name: "new-class",
          query: { courseId: id, title: title },
        });
      }
    }

  async  function accessTraining() {
      const { id, end_date, can_access } = props.course;
      const validatePerfil = [1, 2].includes(user.role_id);
      const formattedEnd = date.formatDate(end_date, "DD/MM/YYYY");

      if (!validatePerfil && !can_access) {
        $q.notify({
          message: `Esse curso só pode ser acessado até a data limite ${formattedEnd}`,
          caption: `Você já pode avaliar ou retirar o seu certificado`,
          type: "warning",
          position: "top",
          timeout: 8000,
        });
        return;
      } else if (!can_access) {
        $q.notify({
          message: `Esse curso só pode ser acessado até a data limite ${formattedEnd}`,
          caption: `Você já pode avaliar ou retirar o seu certificado`,
          type: "warning",
          position: "top",
          timeout: 8000,
        });
      }


      const validate = await accessCourseGroups()

        if (validate){
          await router.push({ name: "training", query: { id: id } });
        }
    }

    async function ValidateActionsPage() {
      let permissionValidate = canUserAccessClass.canUserAccess(
        "edit-trail",
        false
      );

      if (permissionValidate && props.course.tutor_id == user.id) {
        btnEditCourse.value = true;
      } else if (
        permissionValidate &&
        user.role_id === 2 &&
        props.course.edition_other_tutor
      ) {
        btnEditCourse.value = true;
      } else if (permissionValidate && user.role_id === 1) {
        btnEditCourse.value = true;
      } else {
        btnEditCourse.value = false;
      }
    }

    async function sendRatingWiki() {
      let evaluation = starsWiki.value;
      let courseId = props.course.id;

      loading.value = true;

      await http
        .post(`v1/course/${courseId}/rating/${evaluation}`)
        .then((response) => {
          loading.value = false;
        })
        .catch((error) => {
          loading.value = false;
        });

      // await wikiDataServices.evaluation(wikiId, evaluation)
      // .catch((error) => {
      //     alertNotify(error.response.data.toString());
      //     loading.value = false;
      // });

      loading.value = false;
      cardRating.value = false;
    }

    onMounted(() => {
      ValidateActionsPage();
    });

    return {
      //----IMAGES
      // teachImg,
      bookImg,
      folderImg,
      multipleImg,
      bannerImg,
      btnEditCourse,

      loading,
      cardRating,
      finishedTrail,
      finishedOfTutor,
      accessClass,
      starsWiki,
      accessTraining,
      participateCourse,
      sendRatingWiki,
      showDeleteDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
.trilha-card {
  width: 100%;
  // height: 100%;
  padding: 9px 0;

  background: #fdfdfd;
  border-radius: 20px;
  box-shadow: 3px 6px 15px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  flex-direction: row;
  position: relative;

  &::before {
    content: "";
    width: 3px;
    height: 35px;
    //background-color: #dc9de8;
    position: absolute;
    border-radius: 0px 2px 2px 0px;
  }

  .avatar-notification {
    width: 50px;
    height: 44px;
  }

  .title-Info {
    width: 67%;
    //max-width: 340px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h6 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 17px;
      text-overflow: ellipsis;
      width: 97%;
      white-space: nowrap;
      overflow: hidden;
    }
    span {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 17px;
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #0000004d;
    }
  }
}
.section-image-trilha {
  width: 100%;

  .image-trilha {
    border-radius: 10px;
  }

  .section-button-images {
    border-radius: 20px;
    padding: 0px !important;
    margin: 0 0 1% 1%;
    width: 130px;
    background: none;
    /* .button-image {
      background-color: #ffffff;
    } */
  }

  .participate {
    background-color: #fa6b1f;

    .button-image {
      color: #fa6b1f;
    }
  }
  .continue {
    background-color: var(--q-primary, #fe4e64);
    .button-image {
      color: var(--q-primary, #fe4e64);
    }
  }
  .finish {
    background-color: #7cae44;
    .button-image {
      color: #7cae44;
    }
  }
}

.description {
  padding: 0 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 16px;
  color: #0009;
}

.card-relative {
  position: relative;
}

.dialog-main {
  width: 50vw;
}
.card-message-requisite {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000cf;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  div {
    width: 34%;
    margin: 0 auto;
  }
}
.label-title-requisite {
  color: #ffffff;
  font-size: 18px;
  margin: 0 auto;
  font-weight: 600;
}

.info-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  line-height: 11px;
  color: #8c8c8c;
}

.participants {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
  color: var(--q-primary, #fe4e64);
}

.container-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.info-trail {
  display: flex;
}

.item-trail {
  width: 50%;
}

.item-trail + .item-trail {
  margin-top: 8px;
}
</style>
